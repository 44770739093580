import { isRouteErrorResponse, Link, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import React, { type PropsWithChildren, useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { isErrorCausedByResponse } from './services/public';
import { getStaticAssetPath } from './utils/assets';

const logo404 = getStaticAssetPath('images/logo-404.png');

export function ErrorPage(): JSX.Element | null {
  throw new Error('error');
}

function ErrorContainer(props: PropsWithChildren<unknown>): JSX.Element {
  return (
    <div className='fixed inset-0'>
      <div className='w-full h-full flex flex-col items-center justify-center'>
        <img src={logo404} alt='Luna Park Logo' />
        {props.children}
      </div>
    </div>
  );
}

export function Error404Page(props: { capture: boolean }): JSX.Element {
  useEffectOnce(() => {
    props.capture && captureRemixErrorBoundaryError(new Error('404 Not Found'));
  });

  return (
    <ErrorContainer>
      <div className='text-white font-bold'>
        Whoops, this page may not exist.
      </div>
      <Link to='/home' className='underline text-primary mt-3'>
        Back to home
      </Link>
    </ErrorContainer>
  );
}

/** Helper to group errors for user-facing results */
export function resolveError(error: unknown) {
  if (
    (isRouteErrorResponse(error) && error.status === 404) ||
    (isErrorCausedByResponse(error) && error.cause.status === 404) ||
    (error instanceof Response && error.status === 404)
  ) {
    return 404;
  } else if (
    (isRouteErrorResponse(error) && error.status === 401) ||
    (isErrorCausedByResponse(error) && error.cause.status === 401) ||
    (error instanceof Response && error.status === 401)
  ) {
    return 401;
  } else {
    return 'unknown';
  }
}

export function RouteErrorBoundary(props: { error: unknown }): JSX.Element {
  const { error } = props;
  const resolved = resolveError(error);

  // NOTE(drew): even though we're handling 404 here, a client-side 404 will
  // likely be handled inside of _shell-app-routes.$ because it is actually the
  // root-level "splat" route. This means the 404 page requires a LOT of
  // unnecessary JS right now. No way to workaround this except to manually make
  // route files from app-routes, and move the splat to a new file.
  if (resolved === 404) {
    return <Error404Page capture={false} />;
  }

  return (
    <ErrorContainer>
      <div className='text-white font-bold'>
        {resolved === 401 ? (
          <>Redirecting you to login...</>
        ) : (
          <>Something went wrong. Please try refreshing.</>
        )}
      </div>
    </ErrorContainer>
  );
}

/**
 * Use this when partial loading of a page fails, and you do not wish to show a
 * fullscreen error.
 */
export function InvisibleRouteErrorBoundary(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const error = useRouteError();
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') console.error(error);
    captureRemixErrorBoundaryError(error);
  }, [error]);
  return <>{props.children ?? null}</>;
}
